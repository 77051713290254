import classNames from "classnames";
import { twMerge } from "tailwind-merge";

function Subtitle({ children, className }) {

  const classes = twMerge(
    classNames(
      'text-lg font-medium py-1',
      className
    )
  );

  return <h3 className={classes}>
    {children}
  </h3>
}

export default Subtitle;