import Title from "../../components/Title";
import WorkareaContent from "../../components/WorkareaContent";
import ContentCard from "../../components/ContentCard";
import ContentCardContainer from "../../components/ContentCardContainer";
import EmphasisText from "../../components/EmphasisText";
import Paragraph from "../../components/Paragraph";
import pic1 from "../../resources/CorporateAdvisory/pic-1.jpg";
import pic2 from "../../resources/CorporateAdvisory/pic-2.jpg";
import pic4 from "../../resources/CorporateAdvisory/pic-4.jpg";

function CorporateAdvisory() {
  return (
    <WorkareaContent>
      <Title>기업자문의 필요성</Title>
      <Paragraph>
        <p>기업자문이란 기업과 노무법인과의 자문계약을 통해 노동관계 전문가인 공인노무사가 기업의 인사관리, 노사관계, 복리후생 등 인사노무 관리 전반에 걸쳐 상시적인 자문서비스를 제공함으로써 기업이 급속히 변화하는 노동환경에 신속히 대응하고, 법을 준수하며 지속적으로 성장할 수 있도록 지원하는 서비스입니다.</p>
        <p>이러한 기업자문 서비스를 받음으로써 기업은 <EmphasisText>근로관계에서 발생할 수 있는 문제를 사전에 예방</EmphasisText>함으로써 리스크를 줄일  수 있고, <EmphasisText>효과적인 인사노무관리체계를 구축</EmphasisText>함으로서 큰 비용을 절감할 수 있습니다.</p>
      </Paragraph>

      <div className="h-10"></div>

      <Title>기업자문 업무</Title>
      <ContentCardContainer>
        <ContentCard 
          title="노동문제에 대한 법률적 해석 및 검토"
          contents={[
            "해고 및 그 외 인사명령 (대기발령, 휴직 및 정직 등 각종 징계)에 관한 자문",
            "근로자가 제기하는 각종 노동사건에 대한 대응방향 자문"
          ]}
          width={400}
          pic={pic1}
        />

        <ContentCard 
          title="인사제도 정비"
          contents={[
            "현재 노무관리 현황을 진단하고 적법한 근로계약서 작성, 임금체계 설계, 취업규칙 등 사규를 작성하는 컨설팅"
          ]}
          width={400}
          pic={pic2}
        />

        <ContentCard 
          title="노동부점검 대비"
          contents={[
            "노동부 점검대비 각종 규정 검토",
            "정기/수시/기획점검 대비 급여테이블, 근로계약서, 취업규칙 타당성 검토",
            "노동부 지도 점검 대비 각종 서류 사전 검토, 점검후 보완 등 컨설팅"
          ]}
          width={400}
          pic={pic4}
        />

      </ContentCardContainer>

    </WorkareaContent>
  );
}

export default CorporateAdvisory;