import Title from "../../components/Title";
import WorkareaContent from "../../components/WorkareaContent";
import ContentCard from "../../components/ContentCard";
import ContentCardContainer from "../../components/ContentCardContainer";
import EmphasisText from "../../components/EmphasisText";
import Paragraph from "../../components/Paragraph";
import pic1 from "../../resources/IndustrialSafety/pic-1.jpg";
import pic2 from "../../resources/IndustrialSafety/pic-2.jpg";

function IndustrialSafety() {
  return (
    <WorkareaContent>
      <Title>산업안전보건관리의 필요성</Title>
      <Paragraph>
        <p>2022. 1. 27. 중대재해처벌법 시행으로 사업장 내 산업안전보건법 조치 의무의 이행 및 점검이 더욱 중요하게 되었습니다. 중대재해처벌법은 중대재해 발생 시 처벌이 규정되어 있으나 그 본질은 중대재해 발생을 예방하기 위한 법률입니다. </p>
        <p>사업장 내 안전보건관리가 법령을 준수하여 실시되고 있는지 전문가를 통하여 점검하고 미비한 점을 시정함으로써 기업 경영의 가장 기본이 되는 <EmphasisText>산업안전보건의 기반을 다지기 위한 서비스</EmphasisText>를 제공합니다. </p>
      </Paragraph>

      <div className="h-10"></div>

      <Title>산업안전보건관리 업무</Title>

      <ContentCardContainer>
        <ContentCard 
          title="산업안전 노무 관리"
          contents={[
            "안전경영책임계획 및 보고서 분석·검토",
            "안전보건경영 개선사항 도출 · 정비",
            "산업안전 근로감독 대응"
          ]}
          pic={pic1}
        />

        <ContentCard 
          title="중대재해처벌법 대비"
          contents={[
            "재해예방 안전관리체계 구축",
            "재해 재발방지 대책 수립",
            "시정명령 이행 조치"
          ]}
          pic={pic2}
        />
      </ContentCardContainer>
    </WorkareaContent>
  );
}

export default IndustrialSafety;