import MenuButton from './MenuButton';

const Menu = ({ menuItems }) => {

  const renderedMenu = menuItems.map((item) => {
    return <li key={item.key}>
      <MenuButton 
        to={item.path}
        submenus={item.submenus}
      >
        {item.label}
      </MenuButton>
    </li>
  });

  return (
    <nav>
      <ul className='flex flex-row list-none'>
        {renderedMenu}
      </ul>
    </nav>
  );
}

export default Menu;