import Title from "../../components/Title";
import WorkareaContent from "../../components/WorkareaContent";
import ContentCard from "../../components/ContentCard";
import ContentCardContainer from "../../components/ContentCardContainer";
import EmphasisText from "../../components/EmphasisText";
import Paragraph from "../../components/Paragraph";
import pic1 from "../../resources/LaborRepresentation/pic-1.jpg";
import pic2 from "../../resources/LaborRepresentation/pic-2.jpg";
import pic3 from "../../resources/LaborRepresentation/pic-3.jpg";

function LaborRepresentation() {
  return (
    <WorkareaContent>
      <Title>노동사건 대리의 필요성</Title>
      <Paragraph>
        <p>노동사건이란 부당해고, 부당노동행위, 임금체불, 산재, 대지급금 등 근로관계에서 발생하는 다양한 사건을 의미합니다.</p>
        <p>기업 및 근로자는 위와 같은 다양한 노동사건을 전문가인 공인노무사에게 맡김으로써 분쟁을 해결할 수 있으며, 특히 실력 있는 전문가를 선택할수록 본인이 원하는 결과를 얻을 수 있습니다. </p>
        <p>노무법인 안전은 노동사건에 대한 정확한 이슈 파악을 통해 <EmphasisText>신속하고 합리적인 해결책</EmphasisText>을 제시합니다.</p>
      </Paragraph>

      <div className="h-10"></div>

      <Title>노동사건 대리 업무</Title>
      
      <ContentCardContainer>
        
        <ContentCard 
          title="근로복지공단"
          contents={[
            "산재",
            "대지급금 (舊 체당금)"
          ]}
          pic={pic2}
        />
        
        <ContentCard 
          title="노동위원회 사건"
          contents={[
            "부당해고 구제신청",
            "부당노동행위 구제신청",
            "비정규직 차별시정",
            "교섭창구 단일화",
            "노동쟁의 조정신청"
          ]}
          pic={pic3}
        />

        <ContentCard 
          title="노동청 사건"
          contents={[
            "임금체불 진정",
            "부당노동행위 고소 고발",
            "노동관계법 위반 고소 고발",
            "기업 도산에 따른 체당금",
            "단체협약 시정명령 이행 신청"
          ]}
          pic={pic1}
        />

      </ContentCardContainer>
    </WorkareaContent>
  );
}

export default LaborRepresentation;