function ContentCard({ title, contents, pic, width }) {

  const renderedContents = (
    <ul className="pl-7 pr-3 pb-3 list-disc leading-loose">
      {
        contents.map((content, index) => {
          return (
            <li key={index}>{content}</li>
          )
        })
      }
    </ul>
  );

  const widthClass = `w-[${width || 300}px]`

  return (
    <div className={`flex flex-col ${widthClass} border rounded-md mr-4 my-2`}>
      <h3 className="font-semibold text-xl p-3">{title}</h3>
      {pic && 
        <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
          <img src={pic} alt={title} className="absolute top-0 left-0 w-full h-full object-cover pb-3"/>
        </div>
      }
      {renderedContents}
    </div>
  );
}

export default ContentCard;