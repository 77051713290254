import Title from "../../components/Title";
import WorkareaContent from "../../components/WorkareaContent";
import ContentCard from "../../components/ContentCard";
import ContentCardContainer from "../../components/ContentCardContainer";
import EmphasisText from "../../components/EmphasisText";
import Paragraph from "../../components/Paragraph";
import pic1 from "../../resources/Lectures/pic-1.jpg";
import pic2 from "../../resources/Lectures/pic-2.jpg";
import pic3 from "../../resources/Lectures/pic-3.jpg";

function Lectures() {
  return (
    <WorkareaContent>
      <Title>노동법 및 노사관계 교육의 필요성</Title>
      <Paragraph>
        <p>노사간의 분쟁 리스크를 상호 예방하고, 직원 및 조직의 역량을 강화하기 위한 교육대상자별(임원진, 중간관리자, 하위관리자, 일반 구성원 등) 기업별 맞춤형 강의를 제공합니다.
        또한 실무에서 이슈화되는 개정 법령, 근로기준법 전반, 인사노무관리 실무(채용부터 해고까지), 4대 보험처리 실무 등 개별 교육목적에 맞도록 구성한 맞춤형 프로그램을 제공합니다.</p>
        <p>노무법인 안전은 <EmphasisText>현장 실무 경험을 바탕으로 깊이 있는 강의를 제공</EmphasisText>합니다.</p>
      </Paragraph>
  
      <div className="h-10"></div>

      <Title>노동법 및 노사관계 교육</Title>
      <ContentCardContainer>
        <ContentCard 
          title="노동관계법령"
          contents={[
            "노동관계법률 전반",
            "해고, 징계, 임금 등 개별법 사례강의",
            "노동조합 등 집단법 사례강의",
            "노동인권교육"
          ]}
          pic={pic1}
        />

        <ContentCard 
          title="인사노무관련 실무교육"
          contents={[
            "채용부터 해고까지 실무이슈 전반",
            "인사 실무자를 위한 핵심 노동법 강의",
            "최저임금 및 통상임금 대응 이슈",
            "건설업종 사대보험, 노동관련 이슈",
            "스타트업을 위한 노동관련 이슈",
            "신입사원 조직사회화를 위한 관리자 강의"
          ]}
          pic={pic2}
        />

        <ContentCard 
          title="법정의무교육 및 직장내괴롭힘"
          contents={[
            "직장 내 성희롱 예방교육",
            "직장 내 장애인 인식개선 교육",
            "퇴직연금교육",
            "직장내 괴롭힘 방지 교육"
          ]}
          pic={pic3}
        />
      </ContentCardContainer>
    </WorkareaContent>
  );
}

export default Lectures;