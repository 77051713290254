import { useEffect, useRef, useState } from "react";

function NaverMap({ coord }) {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  
  useEffect(() => {
    if (window.naver && window.naver.maps) {      
      const map = new window.naver.maps.Map(mapRef.current, {
        center: new window.naver.maps.LatLng(coord.latitude, coord.longitude),
        zoom: 17,
        zoomControl: true,
        zoomControlOptions: {
          position: window.naver.maps.Position.TOP_RIGHT,          
          style: window.naver.maps.ZoomControlStyle.SMALL
        },
        scrollWheel: false
      });

      new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(coord.latitude, coord.longitude),
        map: map
      });

      setMap(map);
    }

    return () => {
      setMap(null);
    }
  }, []);

  return <div ref={mapRef} className="w-full h-full" />;
}

export default NaverMap;