import ContentCard from "../../components/ContentCard";
import ContentCardContainer from "../../components/ContentCardContainer";
import Title from "../../components/Title";
import Title2 from "../../components/Title2";
import Title3 from "../../components/Title3";
import useMobile from "../../hooks/useMobile";
import profilePic1 from '../../resources/profile-pic-1.png';
import profilePic2 from '../../resources/profile-pic-2.png';
import profilePic3 from '../../resources/profile-pic-3.png';
import profilePic4 from '../../resources/profile-pic-4.png';

function People() {

  const { isMobile } = useMobile();

  const profiles = [
    {
      name: "고동성",
      role: "대표노무사",
      pic: profilePic1,
      contents: [
        {
          title: "학력 및 자격",
          body: [
            "연세대학교",
            "숭실대학교 안전융합대학원 (공학석사)",
            "ISO 45001 심사원",
          ]
        },
        {
          title: "경력",
          body: [
            "위험성평가 중심 안전보건관리 체계구축 컨설턴트",
            "서울특별시 마을노무사",
            "서울특별시 노동환경개선 컨설팅 (산업안전 포함)",
            "중소벤처기업부 비즈니스지원단 현장클리닉 위원",
            "한국공인노무사회 노사협의회 컨설턴트",
            "노사발전재단 일터혁신컨설팅 컨설턴트",
            "다수 기업 직장 내 괴롭힘 조사 및 심의위원회 위원",
            "한국공인노무사회 노사상담센터 상담노무사",
            "",
            "前 중대재해 발생 사업장 명령진단 요원",
            "前 'H공사' 안전관리 지원용역",
          ]
        },
        {
          title: "강사",
          body: [
            "사업장 안전보건교육 강사",
            "성희롱 예방교육 전문강사",
            "장애인인식개선교육 전문강사",
            "서울특별시 노동인권교육 강사",
          ]
        },
        {
          title: "교육이력",
          body: [
            "한국산업안전보건공단 건설업 위험성평가 과정 수료",
          ]
        },
      ],      
    },
    {
      name: "고동경",
      role: "대표노무사",
      pic: profilePic2,
      contents: [
        {
          title: "자격",
          body: [
            "공인노무사 / 산업안전기사 / 건설안전기사 / ISO45001 심사원",
          ]
        },
        {
          title: "경력",
          body: [
            "서울특별시 안전보건지킴이",
            "정부기관 항만·어항 건설현장 및 시설물 안전컨설팅 요원",
            "고용노동부 서울지방노동위원회 및 중앙노동위원회 국선노무사",
            "고용노동부 중부지방고용노동청 고양지청 정보공개심의회 위원",
            "고용노동부 노동청 및 다수 기업 면접위원",
            "다수 민간기업 및 공기업 인사위원회 및 직장 내 괴롭힘 심의위원회 위원",
            "중소벤처기업부 비즈니스지원단 전문위원",
            "메인비즈 경영컨설팅 전문위원단",
            "노사발전재단 일터혁신컨설팅 컨설턴트",
            "고용노동부 근로조건 자율개선 수행 노무사",
            "교육부 직업계고 현장실습 및 취업지원 전담 노무사",
            "한국공인노무사회 노사상담센터 상담노무사",
            "한국공인노무사회 청소년근로권익센터 상담노무사",
            "은평구노동자종합지원센터 찾아가는 노동상담 상담노무사",
            "",
            "(주)한국건설안전공사 중부지역본부",
            "前 S건설 중대재해처벌법 및 산업안전보건법 이행 점검 요원",
            "前 G발전 중대재해처벌법 이행 점검 요원",
            "前 고용노동부 중부지방고용노동청 고양지청",
            "前 한국조선해양",
            "前 현대중공업",
          ]
        },
        {
          title: "강사",
          body: [
            "사업장 안전보건교육 강사",
            "직장 내 장애인 인식개선 교육 강사",
            "서울시교육청 특성화고 노동인권교육 강사",
            "은평구노동자종합지원센터 노동인권교육 강사",
            "동북권서울시노동자종합지원센터 노동인권교육 강사",
            "김포 장애인자립생활센터 근로지원인 양성 교육 노동법 강사",
            "",
            "前 한국공인노무사회 사업주를 위한 노동법 교육 강사",
            "前 한국공인노무사회 퇴직연금 교육사업 전문강사",
            "前 한국장애인고용공단 근로기준법 실무 강사",
            "前 하남시청 플랫폼 프리랜서근로자 노동법 교육 강사",
            "前 고양시비정규직노동자지원센터 기초노동법 교육 강사",
          ]
        },
        {
          title: "수상 및 교육이력",
          body: [
            "공인노무사 연수교육 근로복지공단 이사장 상",
            "이언컨설팅그룹 컨설턴트 우수상",
            "공인노무사회 중대재해처벌법 컨설팅 전문가 과정 수료",
            "한국산업안전보건공단 위험성평가 전문가과정 수료",
            "한국산업안전보건공단 건설업 위험성평가 과정 수료",
          ]
        },
      ],
    },
    {
      name: "안기효 이사 / ",
      role: "수석 컨설턴트",
      pic: profilePic3,
      contents: [
        {
          title: "학력 및 경력",
          body: [
            "광운대학교 행정학과 졸업",
            "ISO45001 심사원",            
          ]
        },
        {
          title: "경력",
          body: [
            "건설자문팀 업무총괄",
            "건설업 4대보험료 환급 및 절감 컨설팅 컨설턴트",
            "근로복지공단 확정정산 및 건강보험공단 지도점검 대응 컨설팅 컨설턴트",
            "",
            "前 서하노동법률사무소 건설자문팀 이사",
            "前 노무법인 이산 건설업 4대보험센터 센터장",
          ]
        },
      ],      
    },
  ]

  const partnerProfiles = [
    {
      name: "김진규",
      role: "자문위원",
      pic: null,
      graduates: [
        //
      ],
      currentCareer: [
        "산업안전지도사",
        "토목시공기술사",
        "건설안전기술사",
        "서울도시주택공사 건설안전 자문위원",
        "한국기술사회 정회원",
        "한국 방재학회 회원",
        "한국건설안전공사 중부지역본부",
      ],
      pastCareer: [
        //
      ],
      etcCareer: [
        //
      ]
    },
    {
      name: "나웅채",
      role: "정형외과 전문의",
      pic: null,
      graduates: [
        //
      ],
      currentCareer: [
        "대한 스포츠의학 인증전문의",
        "대한수영연맹 국가대표 팀 피지션",
        "대한빙상연맹 국가대표 팀 피지션",
        "IOC (국제올림픽위원회) 스포츠의학 인증전문의",
      ],
      pastCareer: [
        //
      ],
      etcCareer: [
        //
      ]
    },
    {
      name: "한철상",
      role: "변호사",
      pic: null,
      graduates: [
        //
      ],
      currentCareer: [
        "법무법인 동하 수석변호사",
        "대법원 국선변호인",
        "경기도청 법률상담위원",
      ],
      pastCareer: [
        //
      ],
      etcCareer: [
        //
      ]
    },
    {
      name: "김연재",
      role: "공인회계사/AICPA",
      pic: null,
      graduates: [
        "카이스트 경영대 석사 과정"        
      ],
      currentCareer: [
        "한미회계법인"
      ],
      pastCareer: [
        "전 삼정회계법인"
      ],
      etcCareer: [
        "SBS Biz 절세미남",
        "JTBC 이론상완벽한남자"
      ]
    },
    {
      name: "조승철",
      role: "국제변호사",
      pic: null,
      graduates: [
        "한동 국제법률대학원 J.D. Equivalent"
      ],
      currentCareer: [
        "CJ제일제당"
      ],
      pastCareer: [
        "전 리앤목특허법인"
      ],
      etcCareer: [
        "US Patent Bar 합격",
        "미국 변호사 (Washington D.C)"
      ]
    },
    {
      name: "윤준",
      role: "공인재무분석사(CFA)",
      pic: null,
      graduates: [
        //
      ],
      currentCareer: [
        "Chartered Financial Analyst",
        "BSc in Economics, University of London",
        "MPhil in Development Studies, University of Oxford",
        "공군 통역 장교",
        "현 한화 Corporate Strategy & Planning",
      ],
      pastCareer: [
        //
      ],
      etcCareer: [
        //
      ]
    },
    {
      name: "이음세무회계사무소",
      role: "",
      pic: null,
      graduates: [
        //
      ],
      currentCareer: [
        "종로구 마을세무사"
      ],
      pastCareer: [
        //
      ],
      etcCareer: [
        //
      ]
    },
  ]

  const spacer5 = <div className="h-5" />
  const spacer10 = <div className="h-10" />

  const renderedCareer = (profile) => (
    <div>
      {
        profile.contents.map((content, index) => {
          return (
            <div className="mb-5">
              <Title3>{content.title}</Title3>
              {
                content.body.map((b, index) => {
                  if (b) {
                    return <p key={index}>{b}</p>;
                  } else {
                    return <div className="h-2"></div>
                  }
                })
              }
            </div>
          );
        })
      }

    </div>
  );

  const renderedProfilesPC = profiles.map((profile) => {
    return (
      <div className="flex flex-row mb-14" key={profile.name}>
        <div className="shrink-0 flex justify-end w-[400px] h-[519px] items-start">
          <img src={profile.pic} alt={`${profile.name}`} className="object-cover"/>
        </div>
        <div className="ml-7">
          <Title2 className='font-bold pt-0'>{profile.name} {profile.role}</Title2>
          {spacer5}
          {renderedCareer(profile)}
        </div>
      </div>
    );
  });

  const renderedProfileMobile = profiles.map((profile) => {
    return (
      <div className="flex flex-col mb-16" key={profile.name}>
        <Title2 className='font-bold pt-0'>{profile.name} {profile.role}</Title2>
        <img src={profile.pic} alt={`${profile.name}`} className="w-[300px] h-[389px] object-cover"/>
        <div>
          {spacer5}
          {renderedCareer(profile)}
        </div>
      </div>
    );
  });

  const renderedPartnerProfiles = partnerProfiles.map((profile) => {
    return (
      <ContentCard 
        key={profile.role + " " + profile.name}
        title={profile.role + " " + profile.name}
        contents={[
          ...profile.graduates,
          ...profile.currentCareer,
          ...profile.pastCareer,
          ...profile.etcCareer
        ]}
        width={300}
      />
    );
  });

  return (
    <div className='p-8'>
      <Title>구성원</Title>
      {spacer10}
      {isMobile ? renderedProfileMobile : renderedProfilesPC}
      {spacer10}

      <Title>자문위원 및 협력 파트너</Title>
      {spacer10}

      <ContentCardContainer>
        {renderedPartnerProfiles}
      </ContentCardContainer>

    </div>
  );
}

export default People;