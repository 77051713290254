import blog from '../resources/blog-icon.png';
import logoWhite from '../resources/logo-white.png';
import useMobile from '../hooks/useMobile';

const Footer = () => {

  const { isMobile } = useMobile();

  const titleStyle = (text) => {
    return <span className='text-gray-400'>{text}</span>;
  };

  const president = <p>{titleStyle('대표')} 공인노무사 고동성 고동경</p>
  const address = <p>{titleStyle('주소')} 서울 관악구 신림로 340 7층 (우 08754)</p>
  const phone = <p className='inline'>{titleStyle('전화')} 02-6951-1729</p>
  const fax = <p className='inline'>{titleStyle('팩스')} 0504-428-9224</p>
  const email = <p className='inline'>{titleStyle('이메일')} cplakds@cplasafety.com</p>
  const copyright = <p>&copy; Copyright 2021 Labor Law Firm An Jeon. All Rights Reserved.</p>

  return (
    <footer className='bg-gray-900 text-gray-400 text-left'>
      <div className="px-8 py-6 w-full max-w-[1024px] mx-auto text-sm">
        <div className={`flex ${isMobile ? 'flex-col space-y-3' : 'flex-row justify-between items-start'}`}>
          
          <div>
            <img src={logoWhite} alt='logo' className='w-[100px] opacity-70 pb-2'/>
            {president}
            {address}
            {
              isMobile ?
              <div>
                {phone} | {fax} <br/>
                {email}
              </div> :
              <div>
                {phone} | {fax} | {email}
              </div>
            }
            {copyright}
          </div>

          <div className={`flex flex-col`}>
            <a href="https://blog.naver.com/hardplayer00" target="_blank" rel="noreferrer">
              <img src={blog} alt='blog icon' className='w-[23px] inline mx-1.5'/>
              <p className='inline text-gray-200 font-medium text-lg'>블로그</p>
            </a>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer;