import Title from "../../components/Title";
import helloImage from "../../resources/greeting.jpg";
import missionImage from "../../resources/mission-vision-corevalue.png";
import missionImageMobile from "../../resources/mission-vision-corevalue-mobile.png";
import useMobile from "../../hooks/useMobile";

function Introduction() {

  const { isMobile } = useMobile();

  return (
    <div className="p-8">
      <Title>인사말</Title>
      <div className="h-5"></div>
      <div className={`flex flex-col md:flex-row items-start space-y-3 md:space-y-0`}>
        <div className="flex-1 text-lg pr-4">
          <p className="text-2xl font-medium pb-3">노무법인 안전을 방문해주셔서 감사합니다.</p>
          <p className="pb-3">노무법인 안전은 명확한 지식과 다양한 경험, 그리고 고객에 대한 존중을 바탕으로 기업자문, 노동사건, 컨설팅, 급여 아웃소싱, 강의ㆍ교육 등 다양한 인사노무분야 업무를 수행하고 있습니다.</p>
          <p className="pb-3">저희의 목표는 전문성과 책임감을 바탕으로 한 최적의 서비스 제공을 통해 고객의 문제를 해결해드리는 것이며, 더 나아가 대한민국 모든 사업장이 안전하고 건강한 일터가 되도록 인사노무전문가로서의 사명을 다하는 것입니다.</p>
          <p>감사합니다.</p>
        </div>
        <div className="md:flex-initial md:w-[380px]">
          <img src={helloImage} alt='greeting'></img>
        </div>
      </div>

      <div className="h-10"></div>

      {
        isMobile ?
        <div>
          <img src={missionImageMobile} alt='mission vision and corevalue'/>
        </div> :
        <div>
          <img src={missionImage} alt='mission vision and corevalue'/>
        </div>
      }
      
    </div>
  );
}

export default Introduction;