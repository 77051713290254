import { useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

function MenuButton({ to, className, children, submenus }) {

  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathnameModified = pathname === '/' ? '/intro/introduction' : pathname;

  const handleClick = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const menuClasses = classNames(
    "relative px-4 md:px-6 py-2 md:py-4 text-xl font-bold	cursor-pointer",
    'text-gray-900',
    className
  );

  const textClasses = classNames(
    pathnameModified.split('/')[1] === to.split('/')[1] && 'text-yellow-500'
  );

  const submenuClasses = (path) => {
    return classNames(
      "block w-full text-base px-4 py-2 font-medium",
      pathnameModified === path && 'text-yellow-500'
    )
  };

  const renderedSubmenus = (
    submenus &&
    <div className={`absolute top-full left-1/2 transform -translate-x-1/2 bg-white w-[140%] shadow-lg transition-opacity duration-150 ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
      {
        submenus.map((item) => {
          return (
            <div 
              key={item.path} 
              className={submenuClasses(item.path)}
              onClick={() => handleClick(item.path)}
            >
              {item.label}
            </div>
          );
        })
      }
    </div>
  );

  return (
    <div 
      className={menuClasses}
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div onClick={() => handleClick(to)} className={textClasses}>
        {children}
      </div>
      {
        renderedSubmenus
      }
    </div>
  );
}

export default MenuButton;