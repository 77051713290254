import Menu from './Menu';
import logo from '../resources/logo.png';
import React from 'react';
import useMobile from '../hooks/useMobile';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPhoneFlip } from "react-icons/fa6";
import { twMerge } from "tailwind-merge";

const Header = () => {

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // '/intro/introduction' path와 '/'는 같은 내용을 보여주므로, 
  // '/' path에 있더라도 '/intro/introduction'처럼 취급함
  const pathnameModified = pathname === '/' ? '/intro/introduction' : pathname;

  const { isMobile } = useMobile();
  const logoPath = '/';

  const phoneNumber = {
    seoul: {
      number: '02-6951-1729',
      label: '서울'
    },
    goyang: {
      number: '02-371-1725',
      label: '고양'
    }
  }

  const menuItems = [
    {
      key: 'intro',
      label: '인사말',
      path: '/intro/introduction',
      submenus: [
        { label: '인사말', path: '/intro/introduction' },
        { label: '구성원', path: '/intro/company' },
        { label: '오시는 길', path: '/intro/location' },
      ]
    },
    {
      key: 'workarea',
      label: '업무분야',
      path: '/workarea/industrial-safety',
      submenus: [
        { label: '산업안전보건', path: '/workarea/industrial-safety' },
        { label: '기업자문', path: '/workarea/corporate-advisory' },
        { label: '급여 및 4대보험', path: '/workarea/salary-and-insurance' },
        { label: '건설노무관리', path: '/workarea/construction' },
        { label: '산재·해고·임금체불', path: '/workarea/labor-representation' },
        { label: '노동조합', path: '/workarea/collective-labor-relations' },
        { label: '맞춤형 강의', path: '/workarea/lectures' },
      ]
    },
    {
      key: 'inquiry',
      label: '온라인 문의',
      path: '/inquiry',
      submenus: []
    }
  ];

  const handleClick = (path) => {
    navigate(path);
  };

  const submenuClasses = (path) => {
    return twMerge(
      classNames(
        "inline-block bg-gray-50 md:bg-transparent text-base px-4 py-1.5 mt-1 mx-0.5 font-medium cursor-pointer border-b-2 border-transparent",
        pathnameModified === path && 'text-yellow-500 border-yellow-500'
      )
    )
  };

  const renderedSubmenus = menuItems.filter((item) => {
    return (pathnameModified.split('/')[1] === item.path.split('/')[1]);
  })[0]
  ?.submenus
  .map((item) => {
    return (
      <div 
        key={item.path} 
        className={submenuClasses(item.path)}
        onClick={() => handleClick(item.path)}
      >
        {item.label}
      </div>
    );
  });

  const phoneButton = (number, label) => {
    const handleClick = () => {
      window.location.href = `tel:${number}`;
    }

    return <div 
      className='bg-yellow-500 text-white text-center pt-1 pb-2 px-2.5 mx-1 rounded-full cursor-pointer'
      onClick={handleClick}
    >
      <FaPhoneFlip className='w-[10px] h-[10px] inline-block'/>
      <div className='inline-block text-xs font-bold pl-1'>{label}</div>
    </div>
  };

  // responsive
  const mainBgStretchHeight = isMobile ? 200 : 500;
  const mainBgNormalHeight = isMobile ? 80 : 200;

  // introduction
  const bgHeight = (pathnameModified === '/intro/introduction') ? mainBgStretchHeight : mainBgNormalHeight;
  const bgInfoOpacity = (pathnameModified === '/intro/introduction') ? 100 : 0;

  const mobileHeader = (
    <header className='flex flex-col items-center gap-1 fixed top-0 bg-white border-b h-[110px] w-full max-w-[1024px] mx-auto left-1/2 transform -translate-x-1/2 z-[1000]'>

      <div className='flex flex-row justify-between items-end w-full px-4'>
        <div onClick={() => {navigate(logoPath)}} className='cursor-pointer pt-2 w-[140px]'>
          <img src={logo} alt="노무법인안전 로고"/>
        </div>

        <div className='flex flex-row mb-1'>
          {phoneButton(phoneNumber.seoul.number, phoneNumber.seoul.label)}
          {phoneButton(phoneNumber.goyang.number, phoneNumber.goyang.label)}
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Menu menuItems={menuItems} />
      </div>
    </header>
  );

  const pcHeader = (
    <header className='flex flex-row justify-between items-end gap-2.5 fixed top-0 bg-white border-b h-[110px] w-full max-w-[1024px] mx-auto left-1/2 transform -translate-x-1/2 z-[1000]'>

      <div onClick={() => {navigate(logoPath)}} className='cursor-pointer w-[270px]'>
        <img src={logo} alt="노무법인안전 로고" />
      </div>

      <div className='flex flex-col items-end mr-7'>
        <div className='flex flex-row space-x-3 text-sm mr-4 mb-0 text-gray-700'>
          {phoneButton(phoneNumber.seoul.number, `${phoneNumber.seoul.label} ${phoneNumber.seoul.number}`)}
          {phoneButton(phoneNumber.goyang.number, `${phoneNumber.goyang.label} ${phoneNumber.goyang.number}`)}
        </div>

        <Menu menuItems={menuItems} />
      </div>
    </header>
  );

  return (
    <div>
      <div className='bg-white w-full fixed h-[110px] border-b z-10'>
      </div>
      
      { isMobile ? mobileHeader : pcHeader }

      <div
        id="main-background-image"
        className={`relative bg-[url(../resources/workbg.jpg)] w-full mt-[110px] bg-no-repeat bg-center bg-cover h-[${bgHeight}px] zoom-effect transition-all duration-500 ease-in-out`}
        style={{ height: `${bgHeight}px` }}
      >
        <div 
          className='w-full h-full transition-all duration-500 ease-in-out'
          style={{ opacity: `${bgInfoOpacity}`}}
        >
          <div className='bg-gray-900 opacity-40 h-full'> 
          </div>
          <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col space-y-1 md:space-y-4 items-center justify-center'>
            <p className='text-white font-semibold text-2xl md:text-4xl'>
              친절한 <span className='font-bold text-yellow-500'>노동법률 전문가</span>로서
            </p>
            <p className='text-white font-semibold text-2xl md:text-4xl'>
              탁월한 서비스를 제공해 드립니다!
            </p>
          </div>
        </div>
      </div>

      <div className='w-full max-w-[1024px] mx-auto px-7'>
        {renderedSubmenus}
      </div>
    </div>
  )
}

export default Header;