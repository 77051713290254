import { useState } from "react";
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import Title3 from '../../components/Title3';
import Spinner from "../../components/Spinner";
import useMobile from "../../hooks/useMobile";
import sendInquiry from "../../api/sendInquiry";

function Inquiry() {

  const { isMobile } = useMobile();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [inquiryText, setInquiryText] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState('');

  const [purposes, setPurposes] = useState([
    { id: 'purpose1', label: '상담', checked: false },
    { id: 'purpose2', label: '견적', checked: false },
  ]);

  const [interestAreas, setInterestAreas] = useState([    
    { id: 'area1', label: '법률자문서비스', checked: false },
    { id: 'area2', label: '급여 아웃소싱', checked: false },
    { id: 'area3', label: '정부지원금 수급', checked: false },
    { id: 'area4', label: '직장 내 괴롭힘', checked: false },
    { id: 'area5', label: '안전보건체계구축', checked: false },
    { id: 'area6', label: '위험성평가', checked: false },
    { id: 'area7', label: '임금체불', checked: false },
    { id: 'area8', label: '대지급금', checked: false },
    { id: 'area9', label: '직무분석', checked: false },
    { id: 'area10', label: '평가체계', checked: false },
    { id: 'area11', label: '보상체계', checked: false },
    { id: 'area12', label: '근로시간제도 개편', checked: false },
    { id: 'area13', label: '산업재해', checked: false },
    { id: 'area14', label: '부당해고', checked: false },
    { id: 'area15', label: '부당노동행위', checked: false },
    { id: 'area16', label: '노동청 점검 대응', checked: false },
    { id: 'area17', label: '건설업 노무관리', checked: false },
    { id: 'area18', label: '근로복지공단 확정정산', checked: false },
    { id: 'area19', label: '건강보험공단 지도점검', checked: false },
    { id: 'area20', label: '4대보험료 환급', checked: false },
  ]);

  const [workplaceLocation, setWorkplaceLocation] = useState('');
  const [workplaceWorkers, setWorkplaceWorkers] = useState('');
  const [preferredContact, setPreferredContact] = useState('');

  const spacer5 = <div className="h-5" />  

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowResult(true);
    doInquiry();
  };

  const handleClose = () => {
    setShowResult(false);
    setResult('');
    resetForm();
  };

  const handlePurpose = (event) => {
    const { id, checked } = event.target;
    const newPurposes = checkUpdatedNewArray(purposes, id, checked);
    setPurposes(newPurposes);
  };

  const handleInterestArea = (event) => {
    const { id, checked } = event.target;
    const newInterestAreas = checkUpdatedNewArray(interestAreas, id, checked);
    setInterestAreas(newInterestAreas);
  };

  const resetForm = () => {    
    const resetPurpose = purposes.map((purpose) => {
      return { ...purpose, checked: false }
    });
    setPurposes(resetPurpose);

    const resetArea = interestAreas.map((area) => {
      return { ...area, checked: false }
    });
    setInterestAreas(resetArea);

    setName('');
    setPhone('');
    setEmail('');
    setWorkplaceLocation('');
    setWorkplaceWorkers('');
    setInquiryText('');
    setPreferredContact('');
  };

  const doInquiry = async () => {
    const title = `${name} 님으로부터 홈페이지를 통한 문의가 왔습니다.`
    const body = [
      `상담 및 견적: ${getCheckedItems(purposes)}`,
      `분야: ${getCheckedItems(interestAreas)}`,
      `이름: ${name}`,
      `전화번호: ${phone}`,
      `이메일: ${email}`,
      `사업장소재지: ${workplaceLocation}`,
      `사업장 상시근로자 수: ${workplaceWorkers}`,
      `문의내용`,
      `---------`,
      `${inquiryText}`,
      `---------`,
      `선호하는 연락방법 및 시간: ${preferredContact}`,
    ].join("\n\n");
    
    const response = await sendInquiry(title, body);
    
    if (response.status === 200) {
      setResult("온라인 문의가 완료되었습니다. 신속하게 답변 드리겠습니다.");
    } else {
      setResult("죄송합니다. 온라인 문의에 실패하였습니다. 직접 연락주시거나 admin@cplasafety.com 으로 이메일 주시면 확인해보겠습니다.");
    }
  };

  const inquiryEnabled = (name.trim().length > 0) && (phone || email);
  const labelClasses = 'pb-1 text-base font-medium text-gray-700';
  const inputClasses = 'mb-7 block p-2 w-full rounded border border-gray-200 shadow-sm';

  const resultPopup = <Modal 
      onClose={handleClose}
      actionBar={<div className="flex justify-center">
        {
          (result) ?
          <button className="bg-yellow-500 text-white text-center py-2 px-4 rounded-md cursor-pointer hover:bg-yellow-600 text-base font-bold w-[120px]" onClick={handleClose}>
            확인
          </button> :
          <Spinner />
        }
      </div>}
    >
      <div className="flex justify-center text-center p-5 pb-8">
        {
          result || '온라인 문의를 전송중입니다.'
        }
      </div>
    </Modal>

  const requiredMark = <span className="text-lg text-red-500"> *</span>;

  const renderedInterestAreas = (
    <div className={`grid ${isMobile ? "grid-cols-2" : "grid-cols-4"} gap-x-4 gap-y-2 my-3`}>
      {
        interestAreas.map((area) => {
          return <div key={area.id}>
            <input 
              type="checkbox"
              id={area.id}
              checked={area.checked}
              onChange={handleInterestArea}
              className='cursor-pointer'
            />
            <label htmlFor={area.id} className="pl-1.5 cursor-pointer text-sm md:text-base">{area.label}</label>
          </div>
        })
      }
    </div>
  );

  const renderedPurposes = (
    <div className={`grid ${isMobile ? "grid-cols-2" : "grid-cols-4"} gap-x-4 gap-y-2 my-3`}>
      {
        purposes.map((purpose) => {
          return <div key={purpose.id}>
            <input 
              type="checkbox"
              id={purpose.id}
              checked={purpose.checked}
              onChange={handlePurpose}
              className="cursor-pointer"
            />
            <label htmlFor={purpose.id} className="pl-1.5 cursor-pointer text-sm md:text-base">{purpose.label}</label>
            </div>
        })
      }
    </div>
  );

  return (
    <div className="p-7">
      <Title>온라인 문의</Title>
      <Title3>검토 후 신속하게 답변 드리겠습니다.</Title3>
      {spacer5}

      <form onSubmit={handleSubmit} className="flex flex-col">

        <label className={labelClasses}>상담 및 견적</label>
        {renderedPurposes}
        {spacer5}

        <label className={labelClasses}>분야</label>
        {renderedInterestAreas}
        {spacer5}

        <label htmlFor="name" className={labelClasses}>이름{requiredMark}</label>
        <input 
          type="text" 
          id="name" 
          className={inputClasses}
          value={name} 
          onChange={(event) => setName(event.target.value)}
        />
      
        <label htmlFor="phone" className={labelClasses}>전화번호 
        { email ? null : requiredMark }
        { (email && phone) ? requiredMark : null }
        </label>
        <input 
          type="tel" 
          id="phone" 
          className={inputClasses}
          value={phone} 
          onChange={(event) => setPhone(event.target.value)}
        />
      
        <label htmlFor="email" className={labelClasses}>이메일 
        { phone ? null : requiredMark }
        { (email && phone) ? requiredMark : null }
        </label>
        <input 
          type="email" 
          id="email" 
          className={inputClasses}
          value={email} 
          onChange={(event) => setEmail(event.target.value)} 
        />
      
        <label htmlFor="workplaceLocation" className={labelClasses}>사업장소재지</label>
        <input 
          type="text"
          id="workplaceLocation" 
          className={inputClasses}
          value={workplaceLocation} 
          onChange={(event) => setWorkplaceLocation(event.target.value)}
        />
      
        <label htmlFor="workplaceWorkers" className={labelClasses}>사업장 상시근로자 수</label>
        <input 
          type="number" 
          id="workplaceWorkers" 
          className={inputClasses}
          value={workplaceWorkers} 
          onChange={(event) => setWorkplaceWorkers(event.target.value)} 
        />

        <label htmlFor="inquiryText" className={labelClasses}>문의내용</label>
        <textarea 
          rows="6" 
          id="inquiryText" 
          className={inputClasses + ' resize-none'}
          value={inquiryText} 
          onChange={(event) => setInquiryText(event.target.value)} 
        />

        <label htmlFor="preferredContact" className={labelClasses}>선호하시는 연락방법 또는 시간</label>
        <input 
          type="text" 
          id="preferredContact" 
          className={inputClasses}
          value={preferredContact}
          onChange={(event) => setPreferredContact(event.target.value)} 
        />

        <div className="flex justify-center">
          <button 
            className={`text-white text-center py-2 px-4 rounded-md text-lg font-bold w-[140px] ${inquiryEnabled ? 'bg-yellow-500 hover:bg-yellow-600 cursor-pointer' : 'bg-gray-200 cursor-not-allowed'}`}
            disabled={!inquiryEnabled}
          >
            문의하기
          </button>
        </div>
      </form>

      {showResult && resultPopup}
    </div>
  );
}

const getCheckedItems = (items) => {
  return items.filter((i) => {
    return i.checked;
  })
  .map((i) => {
    return i.label;
  })
  .join(', ');
}

const checkUpdatedNewArray = (arr, id, checked) => {
  return arr.map((el) => {
    return el.id === id ? { ...el, checked } : el
  });
};

export default Inquiry;