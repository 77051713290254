import ContentCard from "../../components/ContentCard";
import Title from "../../components/Title";
import WorkareaContent from "../../components/WorkareaContent";
import pic1 from "../../resources/Construction/pic-1.jpg";
import pic2 from "../../resources/Construction/pic-2.jpg";
import pic3 from "../../resources/Construction/pic-3.jpg";
import ContentCardContainer from "../../components/ContentCardContainer";
import EmphasisText from "../../components/EmphasisText";
import Paragraph from "../../components/Paragraph";

function Construction() {
  return (
    <WorkareaContent>
      <Title>건설업 노무관리의 필요성</Title>
      <Paragraph>
        <p>건설업은 노동집약적 산업으로서 건설인력에 대한 노무관리는 안정적 기업 운영의 토대가 됩니다. 또한 복잡한 도급구조와 다양한 고용방식으로 관리상의 문제점들이 발생하므로 평소에 노무관리의 체계를 구축하고 원칙에 따른 인사관리를 함으로써 예측불가한 위험 요소에 대비할 필요가 있습니다.</p>
        <p>노무법인 안전은 건설업과 관련한 인사관리의 다양한 측면을 검토하여 <EmphasisText>안정적인 기업 경영을 위한 지원서비스</EmphasisText>를 제공합니다.</p>
      </Paragraph>

      <div className="h-10"></div>

      <Title>건설업 노무관리 업무</Title>

      <ContentCardContainer>
        <ContentCard 
          title="현장 및 일용직 4대보험 신고"
          contents={[
            "4대보험 보험관계 성립신고",
            "고용산재보험 사업개시신고",
            "고용보험 일용직 근로내용확인신고",
            "건강연금보험 사업장 적용신고",
            "일용직 건강연금보험 취득/변경/상실신고",
            "퇴직공제부금 성립/변경/준공신고",
            "퇴직공제부금 일용직 근로내용확인신고",
          ]}
          width={400}
          pic={pic1}
        />

        <ContentCard 
          title="건설업 4대보험료 추징대응 및 컨설팅"
          contents={[
            "고용산재보험 개산확정보험료 신고",   
            "근로복지공단 확정정산",    
            "건강보험공단 지도점검",    
            "국민연금공단 분기별 직권가입",   
          ]}
          width={400}
          pic={pic2}
        />

        <ContentCard 
          title="건설업 노동법률자문 및 일용직 노무관리"
          contents={[
            "건설업 노무제공자 관련 노동법률자문 제공",
            "임금설계 및 근로계약서 제작 및 검토",
            "일용직 법정수당 및 퇴직금 산정",
            "실업급여, 산재사고 관련 자문",
            "산업재해조사표 검토",
          ]}
          width={400}
          pic={pic3}
        />
      </ContentCardContainer>

    </WorkareaContent>
  );
}

export default Construction;