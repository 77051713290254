import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { Routes, Route, Navigate } from 'react-router-dom';

import Introduction from './pages/intro/Introduction';
import Location from './pages/intro/Location';
import People from './pages/intro/People';
import Inquiry from './pages/inquiry/Inquiry';
import CorporateAdvisory from './pages/workarea/CorporateAdvisory';
import CollectiveLaborRelations from './pages/workarea/CollectiveLaborRelations';
import Construction from './pages/workarea/Construction';
import IndustrialSafety from './pages/workarea/IndustrialSafety';
import LaborRepresentation from './pages/workarea/LaborRepresentation';
import Lectures from './pages/workarea/Lectures';
import SalaryAndInsurance from './pages/workarea/SalaryAndInsurance';

function App() {
  return (
    <div className='flex flex-col min-h-screen'>
      <Header />

      <main className='flex-grow w-full max-w-[1024px] mx-auto'>          
        <div>
          <Routes>
            <Route path="/" element={<Introduction />} />
            <Route path="/intro/introduction" element={<Introduction />} />
            <Route path="/intro/company" element={<People />} />
            <Route path="/intro/location" element={<Location />} />
            <Route path="/workarea/collective-labor-relations" element={<CollectiveLaborRelations />} />
            <Route path="/workarea/construction" element={<Construction />} />
            <Route path="/workarea/corporate-advisory" element={<CorporateAdvisory />} />
            <Route path="/workarea/industrial-safety" element={<IndustrialSafety />} />
            <Route path="/workarea/labor-representation" element={<LaborRepresentation />} />
            <Route path="/workarea/lectures" element={<Lectures />} />
            <Route path="/workarea/salary-and-insurance" element={<SalaryAndInsurance />} />
            <Route path="/inquiry" element={<Inquiry />} />
            <Route path="*" element={<Navigate to="/intro/introduction" replace />} />
          </Routes>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default App;
