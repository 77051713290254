import ContentCard from "../../components/ContentCard";
import ContentCardContainer from "../../components/ContentCardContainer";
import Title from "../../components/Title";
import WorkareaContent from "../../components/WorkareaContent";
import EmphasisText from "../../components/EmphasisText";
import Paragraph from "../../components/Paragraph";
import pic1 from "../../resources/SalaryAndInsurance/pic-1.jpg";
import pic2 from "../../resources/SalaryAndInsurance/pic-2.jpg";
import pic3 from "../../resources/SalaryAndInsurance/pic-3.jpg";

function SalaryAndInsurance() {
  return (
    <WorkareaContent>
      <Title>급여 및 4대보험 아웃소싱의 필요성</Title>
      <Paragraph>
        <p>급여 및 4대보험 아웃소싱서비스는 임직원에게 지급하는 급여, 상여와 관련한 일련의 업무를 대행하는 사무지원서비스로서 업무범위는 급여 계산과 4보험 업무 일체, 그리고 퇴직금 계산 등입니다. 기업에서 급여관리는 인사부서의 가장 중요한 업무 중 하나입니다.</p>
        <p>급여관리 기능을 전문 노무법인에 아웃소싱함으로써 <EmphasisText>인사팀의 업무효율성 향상 및 전략적 기능이 강화될 수 있을 뿐만 아니라 비용도 절감</EmphasisText>할 수 있습니다. 또한 근로기준법을 준수하는 급여체계를 구축함으로써 사후에 발생할 수 있는 법적분쟁을 방지할 수 있습니다.</p>
      </Paragraph>

      <div className="h-10"></div>

      <Title>급여 및 4대보험 업무</Title>
      
      <ContentCardContainer>
        <ContentCard 
          title="급여관리" 
          contents={[
            "매월 급여작업 대행 서비스(시간외 근로수당 등)",
            "퇴직금 산정 및 지급(평균임금산정, 미사용연차수당 등)",
            "연차 수당 관리서비스",
          ]}
          pic={pic1}
        />
        
        <ContentCard 
          title="4대보험 관리"
          contents={[
            "국민연금, 건강보험, 고용보험, 산재보험 관리",
            "고용보험, 산재보험 보수총액 신고",
            "산재보험료환급 신청 대행(과오납금, 업종변경 등)",
          ]}
          pic={pic2}
        />

        <ContentCard 
          title="정부지원금 신청"
          contents={[
            "각종 정부지원금 서류 대행"
          ]}
          pic={pic3}
        />      
      </ContentCardContainer>
    </WorkareaContent>
  );
}

export default SalaryAndInsurance;