import classNames from "classnames";
import { twMerge } from "tailwind-merge";

function Title2({ children, className }) {

  const classes = twMerge(
    classNames(
      'text-2xl font-medium py-2',
      className
    )
  )

  return <h2 className={classes}>
    {children}
  </h2>
}

export default Title2;