import classNames from "classnames";
import { twMerge } from "tailwind-merge";

function Title({ children, className }) {

  const classes = twMerge(
    classNames(
      'text-3xl font-semibold py-2',
      className
    )
  );

  return <h1 className={classes}>
    {children}
  </h1>
}

export default Title;