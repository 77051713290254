import NaverMap from '../../components/NaverMap';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import Title2 from '../../components/Title2';
import useMobile from '../../hooks/useMobile';

function Location() {

  const { isMobile } = useMobile();

  const spacer10 = <div className='h-10' />;
  const spacer5 = <div className="h-5" />;

  const mapClass = isMobile ? 'w-full h-[250px] border' : 'w-3/5 h-[480px] border';

  const locationInfos = [
    {
      name: "서울사무소",
      gps: {
        latitude: 37.4847684,
        longitude: 126.930087
      },
      howtos: [
        "대중교통 이용시 신림역 7,8번 출구 르네상스쇼핑몰 7층으로 올라오시면 됩니다.",
        "주차는 50분 무료입니다."
      ],
      address: "서울특별시 관악구 신림로 340 7층",
      phones: [
        "T. 02-6951-1729",
        "M. 010-6389-9224",
        "F. 0504-428-9224",
      ],
      workingHours: [
        "평일 09:00 ~ 18:00 (휴게시간 12:00 ~ 13:00)",
        "주말 및 공휴일 휴무",
      ]
    },
    {
      name: "고양사무소",
      gps: {
        latitude: 37.6491849,
        longitude: 126.9156986
      },
      howtos: [
        "대중교통 이용시 지축역에서 푸르지오 아파트 정문 방향으로 약 150m 걸어오시다 우회전 하셔서 약 100m 걸어오시면 있는 푸리마타워 7층입니다 (도보 3분 거리)",
        "주차는 1시간 무료입니다.",
      ],
      address: "경기도 고양시 덕양구 지축로 70 푸리마타워 707호",
      phones: [
        "T. 02-371-1725",
        "M. 010-3858-8032",
        "F. 0504-346-8032",
      ],
      workingHours: [
        "평일 09:00 ~ 18:00 (휴게시간 12:00 ~ 13:00)",
        "주말 및 공휴일 휴무",
      ]
    },
  ]

  const renderedLocationText = (info) => {
    return (
      <div>
        <Subtitle className='pt-0'>오시는 방법</Subtitle>
        {
          info.howtos.map((howto, index) => {
            return <p key={index}>{howto}</p>
          })
        }
        {spacer5}

        <Subtitle>주소</Subtitle>
        <p>{info.address}</p>
        {spacer5}

        <Subtitle>전화번호</Subtitle>
        {
          info.phones.map((phone, index) => {
            return <p key={index}>{phone}</p>
          })
        }
        {spacer5}
        
        <Subtitle>운영시간</Subtitle>
        {
          info.workingHours.map((hour, index) => {
            return <p key={index}>{hour}</p>
          })
        }
      </div>
    );
  }

  const renderedLocationInfosMobile = locationInfos.map((info) => {
    return (
      <div key={info.name}>
        <Title2>{info.name}</Title2>

        <div className='flex flex-col w-full'>
          <div className={mapClass}>
            <NaverMap coord={info.gps}/>
          </div>
          <div className='w-full'>
            {spacer5}
            {renderedLocationText(info)}
          </div>
        </div>

        {spacer10}
        {spacer10}
      </div>
    );
  });

  const renderedLocationInfosPC = locationInfos.map((info) => {
    return (
      <div key={info.name}>
        <Title2>{info.name}</Title2>

        <div className='flex flex-row w-full'>
          <div className={mapClass}>
            <NaverMap coord={info.gps}/>
          </div>

          <div className='w-2/5 pl-5'>
            {renderedLocationText(info)}
          </div>
        </div>

        {spacer10}
      </div>
    );
  });

  return (
    <div className='p-8'>
      <Title>오시는 길</Title>

      <p>방문 전에 미리 연락주시면 안내드리도록 하겠습니다.</p>

      {spacer10}

      {isMobile ? renderedLocationInfosMobile : renderedLocationInfosPC}

      {spacer10}
    </div>
  );
}

export default Location;