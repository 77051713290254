import { useEffect } from "react";
import ReactDOM from "react-dom";

function Modal({ onClose, children, actionBar }) {

  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    }
  }, []);

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-[2000]">
      <div
        onClick={onClose}
        className="fixed inset-0 bg-gray-300 opacity-80 z-[2000]"
      ></div>
      <div className="p-4 m-5 bg-white rounded-md z-[2000] w-[500px] max-w-[calc(100%-80px)] mx-auto max-h-[calc(100%-80px)] shadow-lg">
        {children}
        {actionBar}
      </div>
    </div>,
    document.querySelector('.modal-container')
  );
}

export default Modal;