import axios from "axios";

const inquiryURL = 'https://mnlixtxicohgxeyd3jjoky2ep40wtcge.lambda-url.ap-northeast-2.on.aws/';

async function sendInquiry(title, body) {

  const response = await axios.post(inquiryURL, {
    title: title,
    body: body,
  });

  return response;
}

export default sendInquiry;