function ProcessCard({ title, contents, width }) {

  const renderedContents = <div className="flex flex-col items-center p-2">
    { contents.map((content) => {
        return <p className="text-center text-sm p-1">{content}</p>
      })
    }
  </div>

  return (
    <div 
      className={`flex flex-col border rounded-md mr-4 my-2`} 
      style={{ width: `${width}px` }}
    >
      <div className="bg-yellow-500 rounded-t-md">
        <h3 className="text-white text-center font-semibold text-xl p-3">{title}</h3>
      </div>
      {renderedContents}
    </div>
  );
}

export default ProcessCard;