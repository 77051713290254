import Title from "../../components/Title";
import WorkareaContent from "../../components/WorkareaContent";
import EmphasisText from "../../components/EmphasisText";
import Paragraph from "../../components/Paragraph";
import ProcessCard from "../../components/ProcessCard";
import { ImArrowRight } from "react-icons/im";
import { ImArrowDown } from "react-icons/im";
import useMobile from "../../hooks/useMobile";

function CollectiveLaborRelations() {

  const { isMobile } = useMobile();

  const rightArrow = <ImArrowRight className="text-yellow-500 mr-4 mt-7"/>
  const downArrow = <ImArrowDown className="text-yellow-500 mr-4"/>
  const arrow = isMobile ? downArrow : rightArrow

  return (
    <WorkareaContent>
      <Title>노동조합 단체교섭 지원</Title>
      <Paragraph>
        <p>공인노무사가 교섭위원으로 위임 받아 직접 교섭에 참여하거나 또는 전문위원으로서 교섭진행을 보조하고 법률적인 조언을 제공하는 역할을 합니다.</p>
        <p>교섭안에 대한 신속·정확한 분석을 통해 최적의 제시안을 마련하고, 적법하고 효율적인 단체교섭을 할 수 있도록 지원하여 <EmphasisText>노동분쟁의 사전 예방 및 노사안정화</EmphasisText>에 기여합니다.</p>
      </Paragraph>
      
      <div className="h-10"></div>

      <Title>노동조합 단체교섭 절차</Title>

      <div className={`flex ${isMobile ? 'flex-col items-center' : 'flex-row items-start' } `}>
        <ProcessCard 
          title="단체교섭 준비"
          contents={[
            "노사관계 현황 파악",
            "교섭위원 교육",
          ]}
          width={`${isMobile ? 200 : 150}`}
        />

        {arrow}

        <ProcessCard
          title="교섭전략 수립"
          contents={[
            "교섭안 검토 및 핵심이슈 파악",
            "교섭항목별 제시안 마련",
          ]}
          width={200}
        />

        {arrow}        

        <ProcessCard 
          title="교섭 진행"
          contents={[
            "쟁점사항에 대한 수정안 마련",
            "교섭과정에서 이슈 발생 시 대응방향 제시",
          ]}
          width={200}
        />

        {arrow}

        <ProcessCard 
          title="단체협약 체결"
          contents={[
            "단체협약 신고 안내",
            "단체협약 이행 및 해석 등 사후관리 지원",
          ]}
          width={200}
        />

      </div>

      <div className="h-10"></div>

      <Title>노사관계 진단 및 파트너십 구축</Title>
      <p>기업의 노사관계 현황, 직원들의 인식 및 주요 이슈 등을 파악하고, 협력적 노사관계를 구축하기 위한 전략을 수립하며, 전략 실행을 위해 세부 이행방안을 도출합니다.</p>
      <p>기본적으로 노사협의회를 활성화하고, 노사 갈등 관리, 협상기법 등에 대한 교육, 복수노조 대비 갈등해소 방안 마련 및 근로시간면제제도 정착 등에 도움을 드립니다.</p>
    </WorkareaContent>
  );
}

export default CollectiveLaborRelations;